import React, { useEffect, useState } from "react";
import { BackArrow } from "../../utility/BackArrow";
import { IoSparklesSharp } from "react-icons/io5";
import { HiMiniSparkles } from "react-icons/hi2";
import { fetchAllData } from "../../utility/fetcher";

export const SpeedCite = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const violationData = JSON.parse(sessionStorage.getItem("filteredViolations")) || [];
    const [plate, setplate] = useState(violationData[currentIndex] === undefined ? "" : violationData[currentIndex].licensePlate);
    const [errorMessage, seterrorMessage] = useState("");
    const [imageSrc, setimageSrc] = useState("");
    const [nextImageSrc, setnextImageSrc] = useState("");
    const [showImage, setshowImage] = useState(true);
    const [preloaded, setpreloaded] = useState(false);
    const [passButton, setPassButton] = useState("Pass");
    const [citeButton, setCiteButton] = useState("Cite");

    const validatePlate = (plate) => {
        if (plate.length === 0) {
            seterrorMessage("License plate cannot be empty");
            return;
        };

        // Check for trailing or leading spaces or dashes
        if (/^-|-$|^\s|\s$/.test(plate)) {
            seterrorMessage("License plate cannot have leading or trailing dashes or spaces");
            return;
        };
    };

    const handlePlateChange = (e) => {
        seterrorMessage("");
        const newValue = e.target.value.toUpperCase();

        if (/[^A-Z0-9- *]/.test(newValue)) {
            seterrorMessage("License plate can only contain letters, numbers, dashes, spaces, and asterisks");
            return;
        };
        if (/--/.test(newValue)) {
            seterrorMessage("License plate cannot have consecutive dashes");
            return;
        };
        if (/- /.test(newValue) || / -/.test(newValue)) {
            seterrorMessage("License plate cannot have a dash and space next to each other");
            return;
        };

        // eslint-disable-next-line
        if (/  /.test(newValue)) {
            seterrorMessage("License plate cannot have consecutive spaces");
            return;
        };
        if (newValue.length > 8) {
            seterrorMessage("License plate cannot be longer than 8 characters in total");
            return;
        };

        setplate(newValue);
    };

    const getPlateImage = async (violation) => {
        const type = 'lpr';
        const path = `/general/getSignedURL?orgID=${violation.orgID}&lot=${violation.lot}&timestamp=${violation.timeParked.split('.')[0]}&type=${type}`;
        const response = await fetchAllData({ 'imageURL': path });

        return response.imageURL.CDN_URL;
    };

    const preloadNextImage = async () => {
        if (currentIndex + 1 < violationData.length) {
            const nextViolation = violationData[currentIndex + 1];
            const nextImageURL = await getPlateImage(nextViolation);
            setnextImageSrc(nextImageURL);
        }
        console.log(`\x1b[32mIMAGE PRELOADED\x1b[0m`);
        setpreloaded(true);
        setCiteButton("Cite");
        setPassButton("Pass");
        setshowImage(true);
    };

    useEffect(() => {
        if (violationData.length > 0) {
            getPlateImage(violationData[currentIndex]).then((url) => setimageSrc(url));
            preloadNextImage();
        }
        // eslint-disable-next-line
    }, []);

    const handleDeny = () => {
        setpreloaded(false);
        setPassButton("Passing...");
        console.log(`\x1b[31mDENIED\x1b[0m`);
        setCurrentIndex((prevIndex) => (prevIndex + 1));
        setimageSrc(nextImageSrc);
        setshowImage(true);
        setplate(violationData[currentIndex + 1].licensePlate);
        preloadNextImage();
    };

    const handleApprove = () => {
        setpreloaded(false);
        setCiteButton("Citing...");
        const finalPlate = plate.replace(/[-\s]/g, '');
        if (finalPlate !== violationData[currentIndex].licensePlate) {
            violationData[currentIndex].licensePlate = plate;
        };

        //TODO Make post request here

        setCurrentIndex((prevIndex) => (prevIndex + 1));
        setimageSrc(nextImageSrc);
        setshowImage(true);
        setplate(violationData[currentIndex + 1].licensePlate);
        preloadNextImage();
    };

    const handlePlateLookup = async (plate) => {
        console.log(`Looking up plate: ${plate}`);
        // const path = `/general/getLicensePlateInfo?plate=${plate}`;
        // const response = await fetchAllData({ 'plateInfo': path });

        // return response.plateInfo;
    }

    const refreshImageLink = async () => {
        const refreshedImageURL = await getPlateImage(violationData[currentIndex]);
        setimageSrc(refreshedImageURL);
        setshowImage(true);
    }

    return (
        <div className="flex flex-col p-8 w-full h-screen items-center pl-20">
            <BackArrow path={"/analytics/current-violations"} />
            <div className="text-spotGray bg-spotYellow transition-all duration-150 rounded-md px-4 py-2 mt-4 group relative">
                <h1 className="flex text-center font-bold text-3xl">Speed Cite</h1>
                <HiMiniSparkles className="absolute group-hover:scale-125 -top-2 -left-2 text-2xl  text-spotGray transition-all duration-500 animate-sparkleFloat" />
                <IoSparklesSharp className="absolute group-hover:scale-125 -bottom-1 -right-2 text-xl text-spotGray transition-all duration-500 delay-[35ms] animate-sparkleFloat" />
            </div>

            {/* COMPARE CONTAINER */}
            {violationData.length > 0 ? (
                <div className="flex flex-col justify-center items-center h-full">
                    <div className="h-full w-full flex flex-col justify-center items-center gap-8">

                        {/* PLATE IMAGE */}
                        {(imageSrc !== "" && showImage) ? (
                            <img
                                src={imageSrc || ""}
                                className="flex rounded-3xl shadow-xl max-w-[80%] h-[60%]"
                                alt="plate"
                                onError={() => {
                                    setshowImage(false);
                                    refreshImageLink();
                                }}
                            />
                        ) : (
                            <h2>Loading...</h2>
                        )}
                        {violationData.length > 0 && (
                            <div className="flex flex-row gap-10 justify-center items-center">
                                <div className="flex flex-col gap-2 text-4xl justify-center items-start grow">
                                    <p className="font-bold text-2xl text-center w-full">License Plate</p>
                                    <input
                                        type="text"
                                        onChange={(e) => handlePlateChange(e)}
                                        onBlur={(e) => validatePlate(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handlePlateLookup(e.target.value);
                                            }
                                        }}
                                        value={plate}
                                        className="font-bold text-center font-mono border-2 border-gray-400 rounded-lg text-[72px] p-4 w-[450px] outline-spotYellow hover:bg-gray-50 active:bg-gray-50 focus:bg-gray-50"
                                    />
                                </div>
                                <div className="flex flex-col gap-2 text-xl w-[70%]">
                                    <div className="flex flex-row gap-1">
                                        <p className="font-bold">Violation: </p>
                                        <p>{violationData[currentIndex].violationType}</p>
                                    </div>
                                    <div className="flex flex-row gap-1">
                                        <p className="font-bold">Spot: </p>
                                        <p>{violationData[currentIndex].spotID} ({violationData[currentIndex].lotName})</p>
                                    </div>
                                    <div className="flex flex-row gap-1">
                                        <p className="font-bold">Time Parked: </p>
                                        <p>{new Date(violationData[currentIndex].timeParked).toLocaleString('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* ERROR MESSAGE */}
                        <p className="text-red-500 text-xl mt-4">{errorMessage}</p>
                    </div>


                    {/* BUTTONS */}
                    <div className="absolute bottom-6 flex flex-row w-[85%] gap-8 h-24">
                        <button className={`flex ${!preloaded && "pointer-events-none"} text-4xl grow rubik-normal select-none justify-center hover:bg-red-300 items-center rounded-xl bg-gray-300 transition-all duration-200 hover:shadow-lg hover:-translate-y-1 hover:text-white`} onClick={handleDeny}>{passButton}</button>
                        <button className={`flex ${!preloaded && "pointer-events-none"} text-4xl grow rubik-normal select-none justify-center items-center rounded-xl bg-spotYellow hover:bg-green-300 hover:shadow-lg hover:-translate-y-1 transition-all duration-200`} onClick={handleApprove}>{citeButton}</button>
                    </div>
                </div>
            ) : (
                <h1 className="text-2xl font-bold text-spotGray mt-20">No violations to cite.</h1>
            )}
        </div>
    );
};

export default SpeedCite;