import React from "react";
import SedanIcon from "../assets/vehicleIcons/sedan.png";
import BikeIcon from "../assets/vehicleIcons/bike.png";
import TruckIcon from "../assets/vehicleIcons/truck.png";
import SuvIcon from "../assets/vehicleIcons/suv.png";
import { formatTimeFromUTC, timeSince } from "../utility/timeHelper";
import Ticket from "../analytics/currentViolations/ticket/Ticket";

const TitleCard = ({ violation, onClose }) => {
    // Map vehicle types to their respective icon file names
    const vehicleTypeIcons = {
        sedan: SedanIcon,
        bike: BikeIcon,
        truck: TruckIcon,
        suv: SuvIcon
    };

    // Function to get the icon based on the vehicle type
    const getVehicleIcon = (vehicleType) => {
        if (vehicleTypeIcons[vehicleType]) {
            return <img src={vehicleTypeIcons[vehicleType]} alt={vehicleType} className="max-h-14" />;
        }
        return (
            <img src={`../../assets/vehicleIcons/sedan.png`} alt="Default Icon" />
        );
    };

    const timeParked = violation.timeParked
    const formattedTimeParked = formatTimeFromUTC(timeParked);
    const totalTimeSinceParked = timeSince(timeParked);

    const SmallCard = () => {
        if (violation.licensePlate) {
            return (
                <div className="z-50 h-44 text-spotGray shadow-xl rounded-3xl bg-white/50 backdrop-blur-md border transition-all duration-50 hover:shadow-2xl w-full mb-4">
                    <button className="absolute right-4 top-4 rounded-full w-6 h-6 text-mg bg-gray-400 z-20 hover:shadow-md" onClick={onClose}>
                        &#x2715;
                    </button>
                    <div className="flex flex-row justify-start px-4 bg-yellow-200 bg-opacity-50 backdrop-blur-md rounded-t-2xl shadow-lg">
                        <div className="w-fit h-auto p-4 group">
                            <div className="group-hover:scale-x-[-1] transition-all duration-1000 ease-in-out cursor-pointer w-fit">
                                {getVehicleIcon(violation.vehicleType)}
                            </div>
                        </div>
                        <div className="flex flex-col text-lg mt-3 font-bold">
                            <p className="header-data">Spot: {violation.lot}-{violation.spot}</p>
                            <p className="header-data">Plate #: {violation.licensePlate}</p>
                        </div>
                    </div>
                    <div className="flex flex-row text-lg px-4 pt-4 justify-between">
                        <div className="felx flex-col">
                            <p>Parked Since: {formattedTimeParked}</p>
                            <p>Parked for: {totalTimeSinceParked}</p>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="flex flex-row justify-start items-center w-fit h-20 bg-white/50 border bg-opacity-50 backdrop-blur-md rounded-2xl shadow-lg px-6 py-2">
                    <button className="absolute right-2 top-2 rounded-full w-6 h-6 text-mg bg-gray-400 z-20 hover:shadow-md" onClick={onClose}>
                        &#x2715;
                    </button>
                    <div className="flex flex-col text-lg mt-3 font-bold">
                        <p className="header-data">Spot: {violation.lot}-{violation.spot}</p>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className="absolute bottom-0 left-32 z-10 flex flex-col justify-end font-rubik">
            {violation.violationType === "" || violation.ticketWritten === true ? (
                <div className="mb-8">
                    <SmallCard />
                </div>
            ) : (
                <div className="">
                    <SmallCard />
                    <Ticket item={violation} map={false} fullName={"Admin"} />
                    {/* <ViolationPage item={violation} map={false} /> */}
                </div>
            )
            }
        </div>
    )
};

export default TitleCard;
