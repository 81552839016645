import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";
import UserPool from "../utility/UserPool";
import { Toaster, toast } from "sonner";
import { postData } from "../utility/fetcher";
import "./login.css";

const saveToCitationDatabase = async (userData) => {
    const path = `/general/spotUsers`;
    const userBuild = {
        "email": userData.email,
        "apps": { //TODO automate this
            "Brigham Young University": {
                "admin": false,
                "enforcement": false,
                "nav": true
            },
            "iHUB": {
                "admin": false,
                "enforcement": false,
                "nav": true
            },
            "Demo": {
                "admin": false,
                "enforcement": false,
                "nav": true
            }
        },
        "citations": [],
        "permits": [],
        "accountCreationDate": new Date().toISOString(),
        "settings": {
            "personalInfo": {
                "firstName": userData.firstName,
                "lastName": userData.lastName
            },
            "vehicles": []
        }
    }

    try {
        const saveData = async () => {
            console.log("Citation data to save:", userBuild);
            const response = await postData(path, userBuild)
            console.log(response.message);
        };

        (async () => {
            await saveData();
        })();
    } catch (error) {
        console.error("Error saving data:", error);
    }
};

export const ConfirmEmail = () => {
    const [confirmationCode, setConfirmationCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [boxPosition, setBoxPosition] = useState("");
    const [codeMessage, setCodeMessage] = useState("");
    const [coverPosition, setCoverPosition] = useState("");
    const userObj = JSON.parse(sessionStorage.getItem("userObj"));
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const user = new CognitoUser({
            Username: userObj.email,
            Pool: UserPool,
        });

        user.confirmRegistration(confirmationCode, true, async (err, result) => {
            if (err) {
                console.error("Error confirming email:", err);
                setErrorMessage(err.message);
                return;
            }

            await saveToCitationDatabase(userObj);

            console.log("Email confirmed successfully:", result);
            toast.success("Email confirmed successfully!");

            const createCustomer = async (userObj) => {
                const response = await fetch('https://ae967eoek9.execute-api.us-east-1.amazonaws.com/default/stripePayment', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        type: 'createCustomer',
                        firstName: userObj.firstName,
                        lastName: userObj.lastName,
                        email: userObj.email,
                    }),
                });

                const data = await response.json();
                console.log('Customer created:', data);
            };

            const stripeBuild = {
                email: userObj.email,
                firstName: userObj.firstName,
                lastName: userObj.lastName,
            }

            createCustomer(stripeBuild);

            setTimeout(() => {
                setCoverPosition("-1900vh");
            }, 500);

            setTimeout(() => {
                navigate("/login");
            }, 2000);

            setTimeout(() => {
                document.body.style.backgroundColor = "white";
            }, 2000);
        });
    };

    const handleResendCode = () => {
        const userData = {
            Username: userObj.email,
            Pool: UserPool,
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.resendConfirmationCode((err, result) => {
            if (err) {
                setCodeMessage(err.message || JSON.stringify(err));
                return;
            }
            setCodeMessage('Confirmation code resent successfully.');
        });
    };

    // Main confirm email tile
    const confirmEmail = () => {
        return (
            <div className="fcc bg-white z-50 rounded-2xl p-12 text-spotGray">
                <h1 className="text-spotGray text-4xl font-bold pb-2">Confirm Email</h1>
                <p className="mb-4 text-center">Enter the confirmation code sent to your email</p>
                <div className="w-full">
                    <form onSubmit={handleSubmit} className="w-full flex flex-col justify-center items-center">
                        <label className="w-full">
                            <div className="font-bold text-xl">
                                Confirmation Code
                            </div>
                            <input
                                type="text"
                                value={confirmationCode}
                                className="w-full h-[40px] border border-gray-400 rounded-lg pl-2 text-spotGray bg-white mb-4"
                                onChange={(e) => setConfirmationCode(e.target.value)}
                            />
                        </label>
                        {errorMessage !== "" ? <p className="text-red-500 fond-bold mb-2 text-[12px] text-center">{errorMessage}</p> : <div className="mb-1"><br /></div>}
                        <p onClick={handleResendCode} className="texl-lg underline">Request a new code</p>
                        {codeMessage !== "" ? <p className="fond-bold mb-2">{codeMessage}</p> : <div className="mb-1"><br /></div>}
                        <button className="w-40 h-12 yellow-gradient rounded-2xl font-bold text-2xl transition-all duration-100 hover:bg-gradient-to-r hover:from-white hover:to-white hover:text-spotYellow hover:border-2 hover:border-spotYellow hover:shadow-lg" type="submit">Confirm</button>
                    </form>
                </div>
            </div>
        );
    };

    // Fancy squares
    const square = (width, height, x, y, rotation, time) => {
        const newY = boxPosition ? boxPosition : y;

        return (
            <div className="background-square" style={{
                width: `${width * .75}%`,
                height: `${height * 1.25}%`,
                transform: `rotate(${rotation}deg)`,
                top: `${newY}vh`,
                left: `${x}vw`,
                transition: `all ${time}s ease-in-out`,
            }}></div>
        );
    };

    // Coverer for screen animation
    const coverer = () => {
        const newY = coverPosition ? coverPosition : "100vh";
        return (<div className="coverer" style={{ top: newY }}></div>);
    };

    document.body.style.overflow = 'hidden';

    return (
        <div className="fcc bg-gradient-to-t from-spotYellow to-spotGray w-screen h-screen fixed top-0 left-0 bottom-0 right-0 overflow-hidden">
            {coverer()}
            <div className="fcc w-screen">
                {confirmEmail()}
            </div>
            <div className="square-div" style={{ overflow: "hidden" }}>
                {square(70, 50, 20, -25, 40, 1.0)}
                {square(70, 70, 0, 80, -20, 2.0)}
                {square(70, 70, 90, 40, -20, 1.5)}
            </div>
            <Toaster />
        </div>
    );
};