import React, { useState } from "react";
import UserPool from "../utility/UserPool"
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { Toaster, toast } from "sonner";
import { useNavigate } from "react-router-dom";

const Signup = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const validateEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setErrorMessage("Invalid email address format.");
            return;
        } else {
            setErrorMessage("");
        }
    }


    const validatePassword = () => {
        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match!");
            return;
        } else {
            setErrorMessage("");
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        validateEmail();
        validatePassword();

        const attributeList = [
            new CognitoUserAttribute({ Name: "given_name", Value: firstName }),
            new CognitoUserAttribute({ Name: "family_name", Value: lastName }),
            new CognitoUserAttribute({ Name: "email", Value: email }),
        ];

        UserPool.signUp(email, password, attributeList, null, (err, data) => {
            if (err) {
                console.error("Error signing up:", err);
                setErrorMessage(err.message);
                return;
            }

            const userObj = {
                email: email,
                firstName: firstName,
                lastName: lastName,
            };

            sessionStorage.setItem("userObj", JSON.stringify(userObj));

            console.log("User registered successfully:", data);
            toast.success("Account created successfully!");

            navigate("/confirmEmail", { state: { email } });
        });
    };

    const inputStyle = {
        width: "100%",
        maxWidth: "500px",
        height: "40px",
        borderRadius: "8px",
        border: "1px solid #ccc",
        marginBottom: "10px",
        paddingLeft: "10px",
        fontSize: "20px",
        color: "#323232",
    };

    const signUp = () => {
        return (
            <div className="flex flex-col justify-center items-center bg-white rounded-3xl shadow-lg p-12 z-50 gap-4">
                <div className="flex flex-col gap-0 justify-center items-center">
                    <h1 style={{ color: "#323232", fontSize: "50px", fontWeight: "bold", marginBottom: "0px", marginTop: "-10px" }}>Sign Up</h1>
                    <p className="flex justify-center items-center w-full text-center text-xl text-spotGray">Please enter an email and a password</p>
                </div>
                <div className="w-full">
                    <form onSubmit={handleSubmit}>
                        <label>
                            <div className="text-xl font-bold mb-2 text-spotGray">
                                First Name
                            </div>
                            <input
                                type="text"
                                value={firstName}
                                style={inputStyle}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </label>
                        <label>
                            <div className="text-xl font-bold mb-2 text-spotGray">
                                Last Name
                            </div>
                            <input
                                type="text"
                                value={lastName}
                                style={inputStyle}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </label>
                        <label>
                            <div className="text-xl font-bold mb-2 text-spotGray">
                                Email
                            </div>
                            <input
                                type="text"
                                value={email}
                                style={inputStyle}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={validateEmail}
                            />
                        </label>
                        <br />
                        <label>
                            <div className="text-xl font-bold mb-2 text-spotGray">
                                Password
                            </div>
                            <input
                                type="password"
                                value={password}
                                style={inputStyle}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </label>
                        <label>
                            <div className="text-xl font-bold mb-2 text-spotGray">
                                Confirm Password
                            </div>
                            <input
                                type="password"
                                value={confirmPassword}
                                style={inputStyle}
                                onChange={(e) => (setConfirmPassword(e.target.value), validatePassword())}
                                onBlur={validatePassword}
                            />
                        </label>
                        {errorMessage !== "" ? <p style={{ color: "red", fontSize: "16px", marginTop: "5px", marginBottom: "-15px" }}>{errorMessage}</p> : null}
                        <br />
                        <br />
                        <button style={{
                            display: "block",
                            justifyContent: "center",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "175px",
                            height: "50px",
                            borderRadius: "20px",
                            backgroundColor: "#FFE374",
                            fontSize: "32px",
                            fontWeight: "bold",
                            color: "white",
                            cursor: "pointer",
                            border: "transparent",
                            boxShadow: "0px 0px 18px 4px rgba(0, 0, 0, 0.12)",
                            textShadow: "0px 0px 18px rgba(0, 0, 0, 0.12)",
                        }} type="submit">Sign Up</button>
                    </form>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        fontSize: "12px",
                        color: "#323232"
                    }}>
                        Already have an account? <a style={{ textDecoration: "underline", marginLeft: "5px" }} href="/login"> Login</a>
                    </div>
                </div>
            </div>
        )
    }

    const square = (width, height, x, y, rotation) => {
        return (
            <div style={{
                position: "absolute",
                width: `${width * .75}%`,
                height: `${height * 1.25}%`,
                backgroundColor: "#D9D9D9",
                transform: `rotate(${rotation}deg)`,
                borderRadius: "45px",
                opacity: "0.2",
                boxShadow: "0 0px 36px 18px rgba(0, 0, 0, 1.45)",
                top: `${y}vh`,
                left: `${x}vw`,
                zIndex: "0",
            }}></div>
        );
    }

    return (
        <div className="flex justify-center items-center" style={{
            background: "linear-gradient(to top, #FFE374, #474747)",
            height: "100vh",
        }}>
            {signUp()}
            <div style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: "1",
                overflow: "hidden",
            }}>
                {square(50, 50, 20, -25, 40)}
                {square(70, 70, 0, 80, -20)}
                {square(70, 70, 90, 40, -20)}
            </div>
            <Toaster />
        </div>
    );
}

export default Signup;