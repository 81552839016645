import React, { useState } from "react";
import ListItem from "./listItem";
import Ticket from "./ticket/Ticket";
import { IoSparklesSharp } from "react-icons/io5";
import { HiMiniSparkles } from "react-icons/hi2";

export const CurrentViolations = () => {
    // const navigate = useNavigate();
    const [filterOption, setFilterOption] = useState("timeParkedAscending");
    const listData = JSON.parse(sessionStorage.getItem("violationData"));
    const [searchTerm, setSearchTerm] = useState("");

    if (listData.length === 0) {
        window.location.href = "/dashboard";
    }

    // Filter out the data that is not a violation or has already been ticketed
    const validListData = listData.filter(
        (item) => item.violationType !== "" && item.ticketWritten === false
    );

    // Handle item click
    const [activeViolation, setActiveViolation] = useState(null);
    const handleItemClick = (item) => {
        sessionStorage.setItem('itemData', JSON.stringify(item));
        setActiveViolation(item);
    };

    // Handle filter change
    const handleFilterChange = (event) => {
        setFilterOption(event.target.value);
    };

    // Apply filter
    const applySorter = (data) => {
        switch (filterOption) {
            case "timeParkedAscending":
                return data.sort(
                    (a, b) => new Date(a.timeParked) - new Date(b.timeParked)
                );
            case "timeParkedDescending":
                return data.sort(
                    (a, b) => new Date(b.timeParked) - new Date(a.timeParked)
                );
            case "violationType":
                return data.sort(
                    (a, b) => a.violationType.localeCompare(b.violationType)
                );
            case "lot":
                return data.sort(
                    (a, b) => a.lot.localeCompare(b.lot, undefined, { numeric: true })
                );
            default:
                return data;
        }
    };

    const sortedListData = applySorter(validListData);

    // Update search term
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    // Filter data based on search term
    const filteredListData = sortedListData.filter((item) =>
        item.licensePlate.toLowerCase().includes(searchTerm.toLowerCase()) && item.licensePlate !== "#UNKN#"
    );

    sessionStorage.setItem("filteredViolations", JSON.stringify(filteredListData));


    return (
        <div className="h-screen overflow-hidden font-rubik">
            <div>
                <div className="flex flex-row justify-between">
                    <div className="flex flex-col grow h-screen overflow-scroll no-scrollbar">
                        <div className="flex flex-row items-center justify-between mr-4">
                            <h1 className="flex items-center text-3xl font-bold justify-center ml-24 mt-4">Current Violations: {sortedListData.length}</h1>
                            <div className="frc gap-4">
                                <div className="relative">
                                    <button
                                        className="bg-gray-300 text-spotGray hover:bg-spotYellow transition-all duration-150 rounded-md px-4 py-2 mt-4 group"
                                        onClick={() => window.location.href = "/analytics/speed-cite"}
                                    >
                                        Speed Cite
                                        <HiMiniSparkles className="absolute opacity-0 group-hover:opacity-100 top-2 -left-1 text-xl  text-spotGray transition-all duration-150" />
                                        <IoSparklesSharp className="absolute opacity-0 group-hover:opacity-100 bottom-1 -right-2 text-lg text-spotGray transition-all duration-150 delay-[35ms]" />
                                    </button>
                                </div>
                                <div className="flex justify-end text-sm w-auto">
                                    <select value={filterOption} onChange={handleFilterChange} className="pl-2 w-56 mt-4 bg-white z-50">
                                        <option value="timeParkedAscending">Time Parked (Ascending)</option>
                                        <option value="timeParkedDescending">Time Parked (Descending)</option>
                                        <option value="violationType">Violation Type</option>
                                        <option value="lot">Lot</option>
                                    </select>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Search License Plate"
                                    className="rounded-md border-2 border-gray-300 pl-2 mt-4 outline-spotYellow"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                        <div>
                            {filteredListData.map((item, index) => (
                                <div key={index} onClick={() => handleItemClick(item)}>
                                    <ListItem
                                        licensePlate={item.licensePlate}
                                        violationType={item.violationType}
                                        lot={item.lot}
                                        spot={item.spot}
                                        timeParked={item.timeParked}
                                        coordinates={item.coordinates}
                                        distance={item.distance}
                                        vehicleType={item.vehicleType}
                                        permitNumber={item.permitNumber}
                                    />
                                </div>
                            ))}
                            {filteredListData.length === 0 && (
                                <h1 className="text-2xl font-bold text-spotGray mt-20">No violations to cite.</h1>
                            )}
                        </div>
                    </div>
                    {activeViolation === null ? null :
                        <div className="flex flex-col justify-end pr-10">
                            <Ticket item={activeViolation} fullName={"Admin"} />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}